import { default as contactSZankz4qRuMeta } from "/application/pages/contact.vue?macro=true";
import { default as forgotNYVEfupgVwMeta } from "/application/pages/forgot.vue?macro=true";
import { default as google_45guideKcM7pKHv1hMeta } from "/application/pages/google-guide.vue?macro=true";
import { default as indexUEHw9xstOJMeta } from "/application/pages/index.vue?macro=true";
import { default as inviteujB2l87jUlMeta } from "/application/pages/invite.vue?macro=true";
import { default as indexPzx8h2pxPfMeta } from "/application/pages/join/index.vue?macro=true";
import { default as infoIEJNZx1Ni1Meta } from "/application/pages/join/info.vue?macro=true";
import { default as loginFLbOmRceC3Meta } from "/application/pages/login.vue?macro=true";
import { default as profilesAwopaWV8mMeta } from "/application/pages/mypage/profile.vue?macro=true";
import { default as securityC3ZG62rnXXMeta } from "/application/pages/mypage/security.vue?macro=true";
import { default as passwordkrcfRUa14kMeta } from "/application/pages/reset/password.vue?macro=true";
import { default as resetVnlIHvLZrkMeta } from "/application/pages/reset.vue?macro=true";
import { default as activityw3kA9LDYiUMeta } from "/application/pages/room/[roomId]/activity.vue?macro=true";
import { default as analyticsc3VpecLb7lMeta } from "/application/pages/room/[roomId]/analytics.vue?macro=true";
import { default as formSlb1H5MPKDMeta } from "/application/pages/room/[roomId]/feedback/form.vue?macro=true";
import { default as formXLIMKI4ptUMeta } from "/application/pages/room/[roomId]/form.vue?macro=true";
import { default as index80ZMTecpudMeta } from "/application/pages/room/[roomId]/index.vue?macro=true";
import { default as indexvvGVZ6JrwbMeta } from "/application/pages/room/[roomId]/member/index.vue?macro=true";
import { default as post7Uq0CIn8ByMeta } from "/application/pages/room/[roomId]/member/post.vue?macro=true";
import { default as ownPPwPMZbZNpMeta } from "/application/pages/room/[roomId]/own.vue?macro=true";
import { default as formSfqgg7fQHqMeta } from "/application/pages/room/[roomId]/post/form.vue?macro=true";
import { default as formla8DieUSWPMeta } from "/application/pages/room/form.vue?macro=true";
import { default as indexNXzKTGUbVGMeta } from "/application/pages/room/index.vue?macro=true";
import { default as squareQjZb65ljQ4Meta } from "/application/pages/room/square.vue?macro=true";
import { default as whaleMEC8wx3WslMeta } from "/application/pages/whale.vue?macro=true";
import { default as whaleLoginQct6iZjLbMMeta } from "/application/pages/whaleLogin.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    meta: contactSZankz4qRuMeta || {},
    component: () => import("/application/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotNYVEfupgVwMeta || {},
    component: () => import("/application/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "google-guide",
    path: "/google-guide",
    meta: google_45guideKcM7pKHv1hMeta || {},
    component: () => import("/application/pages/google-guide.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexUEHw9xstOJMeta || {},
    component: () => import("/application/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite",
    path: "/invite",
    meta: inviteujB2l87jUlMeta || {},
    component: () => import("/application/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: "join",
    path: "/join",
    meta: indexPzx8h2pxPfMeta || {},
    component: () => import("/application/pages/join/index.vue").then(m => m.default || m)
  },
  {
    name: "join-info",
    path: "/join/info",
    meta: infoIEJNZx1Ni1Meta || {},
    component: () => import("/application/pages/join/info.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginFLbOmRceC3Meta || {},
    component: () => import("/application/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mypage-profile",
    path: "/mypage/profile",
    meta: profilesAwopaWV8mMeta || {},
    component: () => import("/application/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: "mypage-security",
    path: "/mypage/security",
    meta: securityC3ZG62rnXXMeta || {},
    component: () => import("/application/pages/mypage/security.vue").then(m => m.default || m)
  },
  {
    name: "reset",
    path: "/reset",
    meta: resetVnlIHvLZrkMeta || {},
    component: () => import("/application/pages/reset.vue").then(m => m.default || m),
    children: [
  {
    name: "reset-password",
    path: "password",
    meta: passwordkrcfRUa14kMeta || {},
    component: () => import("/application/pages/reset/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "room-roomId-activity",
    path: "/room/:roomId()/activity",
    meta: activityw3kA9LDYiUMeta || {},
    component: () => import("/application/pages/room/[roomId]/activity.vue").then(m => m.default || m)
  },
  {
    name: "room-roomId-analytics",
    path: "/room/:roomId()/analytics",
    meta: analyticsc3VpecLb7lMeta || {},
    component: () => import("/application/pages/room/[roomId]/analytics.vue").then(m => m.default || m)
  },
  {
    name: "room-roomId-feedback-form",
    path: "/room/:roomId()/feedback/form",
    meta: formSlb1H5MPKDMeta || {},
    component: () => import("/application/pages/room/[roomId]/feedback/form.vue").then(m => m.default || m)
  },
  {
    name: "room-roomId-form",
    path: "/room/:roomId()/form",
    meta: formXLIMKI4ptUMeta || {},
    component: () => import("/application/pages/room/[roomId]/form.vue").then(m => m.default || m)
  },
  {
    name: "room-roomId",
    path: "/room/:roomId()",
    meta: index80ZMTecpudMeta || {},
    component: () => import("/application/pages/room/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "room-roomId-member",
    path: "/room/:roomId()/member",
    meta: indexvvGVZ6JrwbMeta || {},
    component: () => import("/application/pages/room/[roomId]/member/index.vue").then(m => m.default || m)
  },
  {
    name: "room-roomId-member-post",
    path: "/room/:roomId()/member/post",
    meta: post7Uq0CIn8ByMeta || {},
    component: () => import("/application/pages/room/[roomId]/member/post.vue").then(m => m.default || m)
  },
  {
    name: "room-roomId-own",
    path: "/room/:roomId()/own",
    meta: ownPPwPMZbZNpMeta || {},
    component: () => import("/application/pages/room/[roomId]/own.vue").then(m => m.default || m)
  },
  {
    name: "room-roomId-post-form",
    path: "/room/:roomId()/post/form",
    meta: formSfqgg7fQHqMeta || {},
    component: () => import("/application/pages/room/[roomId]/post/form.vue").then(m => m.default || m)
  },
  {
    name: "room-form",
    path: "/room/form",
    meta: formla8DieUSWPMeta || {},
    component: () => import("/application/pages/room/form.vue").then(m => m.default || m)
  },
  {
    name: "room",
    path: "/room",
    meta: indexNXzKTGUbVGMeta || {},
    component: () => import("/application/pages/room/index.vue").then(m => m.default || m)
  },
  {
    name: "room-square",
    path: "/room/square",
    meta: squareQjZb65ljQ4Meta || {},
    component: () => import("/application/pages/room/square.vue").then(m => m.default || m)
  },
  {
    name: "whale",
    path: "/whale",
    meta: whaleMEC8wx3WslMeta || {},
    component: () => import("/application/pages/whale.vue").then(m => m.default || m)
  },
  {
    name: "whaleLogin",
    path: "/whaleLogin",
    component: () => import("/application/pages/whaleLogin.vue").then(m => m.default || m)
  }
]

const PATH = '/apin/v2/licenses';

export interface IProduct {
    productName: string;
}

export interface ILicenseInfo {
    licenseId: number;
    startDatetime: string;
    endDatetime: string;
    product: IProduct | undefined;
}

// 유저 라이센스 소유자 여부 조회. 작업자: 김정현, 2024-08-29
export const getUserLicense = () => request({
    url: `${PATH}/mine`,
    method: 'GET',
});
  
const JAJAK_TOKEN_KEY = 'jajakToken';

export const useJajakToken = () => {
  if (process.env.NODE_ENV === 'production') {
    return useCookie(JAJAK_TOKEN_KEY, {
      domain: '.jajakjajak.com',
      path: '/',
    });
  }
  return useCookie(JAJAK_TOKEN_KEY, { path: '/' });
};
